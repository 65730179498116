<template>
  <div id="faq" class="faq-section ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="faq-accordion">
            <h1>What We Do</h1>
            <br />
            <ul class="accordion accordion-2">
              <li class="accordion-item" v-for="(item, i) in data" :key="item.id">
                <a
                  class="accordion-title"
                  :class="i === 0 ? 'active' : ''"
                  href="javascript:void(0)"
                >
                  <i class="fa fa-plus"></i>
                  {{ item.title }}
                </a>

                <div class="accordion-content" :style="i === 0 ? 'display: block;' : '' ">
                  <div v-html="item.description" class="white-space-pre-wrap"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 d-flex align-items-center" v-lazy-container="{ selector: 'img' }">
          <img class="kailash-img" :data-src="require('../assets/img/make-impact-kailash.jpg')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: "MakeImpactArea",
  props: {
    data: {
      type: [Array, Object],
      default: null
    }
  },
  mounted() {
    $(function () {
      $('.accordion').find('.accordion-title').on('click', function () {
        // Adds Active Class
        $(this).toggleClass('active');
        // Expand or Collapse This Panel
        $(this).next().slideToggle('fast');
        // Hide The Other Panels
        $('.accordion-content').not($(this).next()).slideUp('fast');
        // Removes Active Class From Other Titles
        $('.accordion-title').not($(this)).removeClass('active');
      });
    });
  }
}
</script>

<style scoped lang="scss">
  @media (max-width: 992px) {
    .kailash-img {
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .kailash-img {
      width:80%;
      margin: 20px auto;
    }
  }

  .faq-section {
    padding-top:0;
    padding:0;
    background-image:url('../assets/img/green-bg.jpg');
    background-size:cover;
  }

  .faq-section .row {
    background-color:#31bfd7;

  }

  .faq-section h1 {
    color:#fff;
    font-size: 35px;
  }

  .faq-accordion .accordion .accordion-item {
    border-color: #fff;
    border-left: 2px solid #11899d;
  }
</style>
